const requestToken = process.env.REACT_APP_USER_TOKEN;
const urlPrefix = process.env.REACT_APP_BACKEND_URL;
const pb_key = process.env.REACT_APP_CLOUDPAYMENTS_PUBLIC_ID;
const orderRequestToken = process.env.REACT_APP_ORDER_TOKEN;
const orderUrlPrefix = process.env.REACT_APP_ORDER_URL;

const apiUrls = {
  loadMenu: urlPrefix + '/menu/actual/',
  loadCurrentMenu: urlPrefix + '/regular-payment/menu',  // ???
  order: urlPrefix + '/regular-payment/payment/',
  points: urlPrefix + '/menu/points',
  genQR: urlPrefix + '/regular-payment/payment/status/',
};

const maxOrderCost = 600;

export {
  requestToken,
  urlPrefix,
  apiUrls,
  pb_key,
  maxOrderCost,
  orderRequestToken,
  orderUrlPrefix,
};