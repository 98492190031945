import React, {useEffect, useRef, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {loadPoints} from '../../redux/modules/cart';
import {loadMenu, showMore} from '../../redux/modules/menu';
import SearchInput from "../SearchInput/SearchInput";
import CategoriesFilter from './CategoriesFilter/CategoriesFilter';
import MenuItem from './MenuItem/MenuItem';


Array.prototype.searchFilter = function (fn, key, searchText) {
  if (!searchText)
    return this;

  const filtered = [];
  for (let i = 0; i < this.length; i++) {
    if (this[i][key].toLowerCase().includes(searchText.toLowerCase()))
      filtered.push(this[i]);
  }
  return filtered;
}
const Menu = ({setActive}) => {
  const menuTarget = useRef(null);
  const dispatch = useDispatch();
  const {inCartIndexList, items: cart} = useSelector(state => state.cart);
  const {
    items, activeFilterIndex, allItemsLimit, dates
  } = useSelector(state => state.menu);
  const [loadTimer, setLoadTimer] = useState();
  const [searchText, setSearchText] = useState('')

  useEffect(() => {
    dispatch(loadPoints());
  }, []);

  useEffect(() => {
    dispatch(loadMenu());
  }, []);

  const showMoreItems = async () => {
    if (!loadTimer) {
      setLoadTimer(
        setTimeout(async () => {
          await dispatch(showMore());
        }, 1000)
      );
    } else {
      setLoadTimer(null);
    }
  };

  const scrollToMenu = (event) => {
    event.preventDefault();
    menuTarget.current.scrollIntoView({
      behavior: 'smooth'
    });
  };
  document.body.addEventListener('scroll', async () => {
    const cardElement = document.querySelector('.card');
    if (cardElement) {
      if (
        document.querySelector('main').offsetHeight - cardElement.offsetHeight - 200 <=
        document.body.scrollTop + 450
      ) {
        await showMoreItems();
      }
    }
  });


  return (
    <main className="main">
      <section className="section-shop">
        <div className="container">
          <h1>
            Оплата покупок
            {' '}
            <i>R</i>
            eat
            <b>m</b>
            e
          </h1>
          <p>
            Здесь вы можете оплатить свою покупку
          </p>
          <a onClick={scrollToMenu} href="#">Оплатить</a>
        </div>
      </section>
      <section ref={menuTarget} className="section-product">
        <div className="container">
          {/* <h1>Меню<span>Для оплаты покупки наберите товары в корзину, после чего в корзине нажмите оплатить</span></h1> */}
          <h2>Вы взяли нашу продукцию из холодильника, но по техническим причинам не смогли её сразу оплатить?<br/><br/>
            Вы можете оплатить вашу покупку здесь.<br/>
            Подберите товары в корзину и перейдите к оплате.<br/><br/>
            Не забудьте указать правильный адрес микромаркета.<br/><br/>
            Спасибо за то, что вы с нами!</h2>
            <SearchInput onSearchChange={setSearchText}/>
          {/* <CategoriesFilter /> */}
          <ul className="list">
            {
              (activeFilterIndex !== 0)
                ? items.map((item, index) => (
                  (item.category_id === activeFilterIndex) &&
                  <MenuItem key={`${item.good_id}-${index}`} cart={cart} item={{index, ...item}}
                            inCartIndexList={inCartIndexList} setActive={setActive}/>
                ))
                : items.searchFilter(()=>{}, 'good_name', searchText).map((item) => {
                  const index = items.findIndex(srcItem => srcItem.id === item.id);

                  if (searchText)
                    return <MenuItem key={`${item.good_id}-${index}`} item={{index, ...item}} cart={cart} inCartIndexList={inCartIndexList} setActive={setActive}/>
                  return ((index < allItemsLimit && item.category_id !== 99999999) && <MenuItem key={`${item.good_id}-${index}`} item={{index, ...item}} cart={cart} inCartIndexList={inCartIndexList} setActive={setActive}/>)})
            }
          </ul>
          {(!searchText && activeFilterIndex === 0 && allItemsLimit <= items.length) &&
            <button onClick={showMoreItems} className="button">Показать еще</button>}
        </div>
      </section>
    </main>
  );
};

export default Menu;
