import React, {useEffect, useState} from 'react';
import searchIcon from '../../assets/images/search.svg'
import clearIcon from '../../assets/images/clear.svg'
import styles from './SearchInput.module.scss';
const SearchInput = (props) => {
  const {onSearchChange = () => {}} = props;
  const [searchText, setSearchText] = useState('');

  useEffect(()=> {
    onSearchChange(searchText);
  }, [searchText])

  const handleChange = (e) => {
    setSearchText(e.target.value)
  }

  const handleClear = () => {
    setSearchText('')
  }

  return (
    <div className={styles.SearchInput}>
      <img src={searchIcon} />
      <input placeholder="Введите наименование товара" value={searchText} onChange={handleChange}/>
      {searchText.length > 0 && <img className={styles.clearBtn} src={clearIcon} title="очистить" onClick={handleClear}/>}
    </div>
  );
};

export default SearchInput;
