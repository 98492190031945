import React from 'react';
import Select, { components } from 'react-select';

const CastomSelect = ({onChange, options, value, classNamePrefix}) => {
    const defaultValue = (options,value) => {
        return options ? options.find( option => option.value === value ) : ""
    }
    const Placeholder = (props) => {
        return <components.Placeholder {...props} />;
    };
        
    return (
        <div className={classNamePrefix}>
            <Select
            name="point_id"
            classNamePrefix="custom_select"
            value={defaultValue(options, value)}
            onChange={ value=> onChange(value)}
            options={options}
            components={{ Placeholder }}
            getOptionLabel={selectOptions => `${selectOptions.name}: ${selectOptions.label}`}
            placeholder={'Выберите пункт продажи'}
            />
        </div>
    );
};

export default CastomSelect;