import React from 'react';
import logofooter from '../../assets/images/reatme-footer.svg'
import styles from './Footer.module.scss'
import cn from 'classnames'

const Footer = () => {
    return (
          <footer className={styles.footer}>
            <div className={cn(styles.container,styles.container_grid)}>
              <div className={styles.wrapper}>
                <div>
                  <img src={logofooter} alt=""/>
                  {/* <a href="https://vk.com/reatme">
                    <img src={vk} alt=""/>
                  </a> */}
                </div>
                <p>ООО "Алгоритм" © Все</p>
                <p>Права Защищены.</p>
              </div>
              <div className={styles.wrapper}>
                {/* <a href="https://yandex.ru/maps/213/moscow/house/volokolamskoye_shosse_89/Z04YcgZgTEABQFtvfXRzeHxlYw==/?ll=37.410555%2C55.829067&z=17.16">Москва, Волоколамское шоссе, 89</a> */}
                <a href="https://t.me/ReatmeBot">Наш телеграм-бот</a>
              </div>
              <div className={styles.wrapper}>
                <a href="tel:+74952588444">+7 495 25 88 444</a>
                <a href="mailto:welcome@reatme.ru">welcome@reatme.ru</a>
              </div>
            </div>
          </footer>
    );
};

export default Footer;
