import { Form, Formik } from 'formik';
import toast from 'react-hot-toast';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import cn from 'classnames';
import * as yup from 'yup';
import CustomSelect from '../.././components/CastomSelect/CastomSelect'
import { maxOrderCost } from "../../config";
import {
  formatter, getDefaultPoint, loadUserCookie, saveDeliveryCookie
} from '../../helpers/utils';
import {
  checkQR, clearCart, clearSavedInOtherDateCart, moveItemToInOtherDate, removeItem, sendOrder
} from '../../redux/modules/cart';
import { useMediaQuery } from 'usehooks-ts';

import CartItem from './CartItem/CartItem';

import * as Sentry from '@sentry/react';


import styles from './Cart.module.scss';
import Closed from '../../assets/images/close.png';
import PublicOferta from '../../assets/documents/PublicOferta.pdf'
import PoliticConfidentiality from '../../assets/documents/PoliticConfidentiality.pdf'

const initFormValue = {
  ...loadUserCookie(),
  point_id: getDefaultPoint().value,
  sel_index: getDefaultPoint().index,
  is_payment: false,
};

const Cart = ({ active, setActive }) => {
  const dispatch = useDispatch();
  const {
    items, cartCount, pointsList,
  } = useSelector(state => state.cart);
  const { items: menu, percent, activeDate } = useSelector(state => state.menu);
  const [orderSent, setOrderSent] = useState(false);
  const [orderSentInfo, setOrderSentInfo] = useState();
  const [isPaymentOnline, setIsPaymentOnline] = useState(false);
  const [isPaymentOnlineSuccess, setIsPaymentOnlineSuccess] = useState(true);
  const [qrInfo, setQrInfo] = useState(false);
  const screen = useMediaQuery('(max-width: 768px)');
  const [closeTimer, setCloseTimer] = useState(false);
  useEffect(() => {
    if (typeof items !== 'object') {
      dispatch(clearCart());
      dispatch(clearSavedInOtherDateCart());
    }
  });
  useEffect(() => {
    // console.log(qrInfo);
    const interval = setInterval(async () => {
      if (qrInfo) {
        console.log('Check payment status for '+qrInfo.Data.qrcId);
        const result = await dispatch(checkQR(qrInfo.Data.qrcId));
        if (result=='Accepted') {
          console.log("success")
          setQrInfo(false)
          setOrderSent(true)
          hundleCartRemove()
        }
        if (result=='Rejected') {
          console.log("fail")
          setQrInfo(false)
          showErrorPay()
        }
      }
    }, 3000);    
    return () => clearInterval(interval);     
  }, [qrInfo]);

  // useEffect(() => {
  //   dispatch(removeUnactive(activeDate));
  // }, [activeDate])

  if (!menu.length) { return <></>; }
  const calculateCartTotal = () => {
    let localTotal = 0;
    items.forEach(cartItem => {
      if (cartItem.date == activeDate && menu.find(item => item.good_id == cartItem.menu_item)) {
        localTotal += menu.find(item => item.good_id == cartItem.menu_item).good_price * cartItem.quantity;
      }
    })
    return localTotal;
  };

  const validationSchema = yup.object().shape({
    point_id: yup.number().oneOf(pointsList.map(point => point.id)).required('Выберите пункт продажи')
  });

  const removeCartItem = (goodId, price) => {
    dispatch(removeItem(goodId, price));
  };

  const moveToOtherDate = (goodId, price) => {
    dispatch(moveItemToInOtherDate(goodId, price));
  };

  const handlePointChange = ({ value, index }, handleChange) => {
    saveDeliveryCookie({ value, index });

    const event = {
      persist: () => { },
      target: {
        type: 'change',
        value,
        name: 'point_id'
      }
    };
    handleChange(event);
  };

  const showErrorNotification = () => toast.error('Некоторые товары из корзины не доступны для заказа в выбранную дату', {
    style: {
      borderRadius: '10px',
      background: '#333',
      color: '#fff'
    }
  });

  const showErrorPay = () => toast.error('К сожалению, срок действия QR-кода истёк. Вы можете повторно нажать кнопку "Оплатить по СБП".', {
    duration: 6000,
    style: {
      borderRadius: '10px',
      background: '#333',
      color: '#fff'
    }
  });
/*
  function pay(order) {
    const widget = new cp.CloudPayments();
    setIsPaymentOnlineSuccess(true);

    widget.pay('charge', // или 'auth'
      { //options
        publicId: pb_key, //id из личного кабинета
        description: 'Оплата товаров в order.reatme.ru', //назначение
        amount: calculateCartTotalWithDiscount() / 100, //сумма
        currency: 'RUB', //валюта
        autoClose: 3, //время в секундах до авто-закрытия виджета (необязательный)
        invoiceId: order.id, //номер заказа  (необязательно)
        email: order.email, //email плательщика (необязательно)
      },
    ).then(async (result) => {
      // await axios.post(apiUrls.pay, {
      //   id: order.id,
      //   guid: order.guid,
      //   transaction_id: result.data.transactionId
      // }, getRequestConfig());
      // setOrderSent(true);
      if (result.type == 'cancel') {
        setIsPaymentOnlineSuccess(false);
      }
      hundleCartRemove();
    }).catch((error) => {
      // Обработка ошибки при вызове метода widget.pay
      Sentry.captureException(error);
      setIsPaymentOnlineSuccess(false);
      showErrorPay();
      hundleCartRemove();
    });
  }
*/

  const drawQR = (qr) => {
    return (
      screen
        ? <div className={styles.cart_modal2}>
          <button className={styles.btn_modal2} onClick={(e) => { e.stopPropagation(); e.preventDefault(); setQrInfo(false); }}>
            <img src={Closed} alt="exit" />
          </button>
          <div className={styles.wrapper2}>
            <a href={`${qr.Data.payload}`} target='_blank'>Перейдите по ссылке для оплаты заказа</a>
          </div>
        </div>
        : <div className={styles.cart_modal2}>
          <button className={styles.btn_modal2} onClick={(e) => { e.stopPropagation(); e.preventDefault(); setQrInfo(false); }}>
            <img src={Closed} alt="exit" />
          </button>
          <div className={styles.wrapper2}>
            <h1>Отсканируйте QR-код для оплаты</h1>
            <img src={`data:${qr.Data.image.mediaType};base64,${qr.Data.image.content}`} />
          </div>
        </div>
    )
  };
/*
  function pay_sbp(order) {
    try {
      setIsPaymentOnlineSuccess(true);

      // вызвать СБП с point_id и guid
      dispatch(registerQR(order.point_id, order.guid)).then((result) => {
        // TODO: отрисовать как-то qr-код
        setQrInfo(result)
        // drawQR(result);
        // и включить таймер проверки статуса qr
        // dispatch(checkQR(result.Data.qrcId))

      });

    } catch (e) {
      errorNotify(e.message);
    }
  }
*/

  const handlePaySBP = (handleChange) => {
    const event = {
      persist: () => { },
      target: {
        type: 'change',
        value: true,
        name: 'is_payment'
      }
    };
    handleChange(event);
    setIsPaymentOnline(true);
  };

  const hundleCartRemove = () => {
    setOrderSent(true);
    dispatch(clearCart());
    let timeout = setTimeout(() => {
      if (active == true) {
        setActive(false);
        setOrderSent(false);
        setOrderSentInfo(undefined);
      }
    }, 15000);
    setCloseTimer(timeout);
  }

  const selectOptions = pointsList.map((point, index) => ({
    value: point.id,
    label: point.address,
    name: point.name,
    index
  }));

  const errorNotify = e => toast.error(e, {
    style: {
      borderRadius: '10px',
      background: '#333',
      color: '#fff'
    }
  });

  const formSubmit = async (values) => {
    try {
      if (items.length == 0) {
        errorNotify(`Не выбраны товары`);
        return;
      }
      if (!isPaymentOnline /*&& !isPaymentOnlineSBP*/ && Number(calculateCartTotal() / 100) > maxOrderCost) {
        errorNotify(`Максимальная сумма заказа - ${formatter.format(maxOrderCost)}`);
        return;
      }
      if (items.filter(item => item.date != activeDate).length > 0) {
        errorNotify(`Меню могло измениться, Вам необходимо выбрать товары заново`);
        return;
      }
      setIsPaymentOnlineSuccess(true);
      const { data: result } = await dispatch(sendOrder({ ...values }));
      values.items = items;

      setQrInfo(result)
      // включить таймер проверки статуса qr
      // dispatch(checkQR(result.Data.qrcId))

      setOrderSentInfo(values);
    } catch (e) {
      errorNotify(e.message);
      Sentry.captureException(e)
    }
  };

  const renderOrderSent = () => {
    if (orderSent) {
      if (isPaymentOnlineSuccess) {
        return (
          <div className={styles.orderSent}>
            <h1>Спасибо за покупку!</h1>
            <hr />
            <p>Список товаров</p>
            <ul>
              {orderSentInfo.items.map((item, index) => (
                <li key={index}>{index + 1}. {menu[item.index].good_name}</li>
              ))}
            </ul>
          </div>
        );
      }
      return (
        <div className={styles.orderSent}>
          <h1>Ваш заказ не был оформлен из-за ошибки оплаты.</h1>
          <p>Пожалуйста, создайте заказ заново</p>
        </div>
      );
    }
  };

  return (
    <div className={cn(styles.cart_modal, { [styles.active]: !active })}>
      <button className={styles.btn_modal} onClick={(e) => { 
        e.stopPropagation(); 
        e.preventDefault(); 
        setActive(); 
        setOrderSent(false); 
        setOrderSentInfo(undefined);
        if (closeTimer) { 
          clearTimeout(closeTimer);
          setCloseTimer(null)
        }
        }}>
        <img src={Closed} alt="exit" />
      </button>
      <div className={styles.wrapper}>
        {
          qrInfo
            ? drawQR(qrInfo)
            : <></>
        }
        {renderOrderSent()}
        <hr />
        <ul className={styles.list}>
          {items.map(cartItem => (
            <li key={`cartitem-${cartItem.menu_item}`} className={styles.card}>
              <CartItem
                cartItem={cartItem}
                menuItem={menu[cartItem.index]}
                removeCartItem={removeCartItem}
                inOtherDate={activeDate !== cartItem.date}
                moveToOtherDate={moveToOtherDate}
                showErrorNotification={showErrorNotification}
              />
            </li>
          ))}
        </ul>
        <span>
          {`Итого: ${formatter.format(calculateCartTotal() / 100)}`}
        </span>
        <hr />
        <Formik
          initialValues={initFormValue}
          validationSchema={validationSchema}
          onSubmit={(values, { resetForm }) => {
            formSubmit(values, resetForm);
          }}
        >
          {({
            values, touched, errors, handleChange
          }) => (
            <Form noValidate autoComplete="off">
              <div className={cn({ [styles.invalid]: errors.point_id && touched.point_id })}>
                <CustomSelect
                  id="point_id"
                  options={selectOptions}
                  value={values.point_id}
                  onChange={e => handlePointChange(e, handleChange)}
                />
              </div>
              <label htmlFor="point_id">{errors.point_id ? 'Выберите пункт продажи' : ''}</label>
              <p>Нажимая на кнопку "Оплатить", вы соглашаетесь с <a href={PoliticConfidentiality} target="_blank">Политикой конфиденциальности</a> и <a href={PublicOferta} target="_blank">условиями оферты</a>.</p>
              <button onClick={() => { handlePaySBP(handleChange) }}>Оплатить по СБП</button>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default Cart;
